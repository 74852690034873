import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Menu } from 'antd';
import routeList from '@src/router';
import { GlobalContext } from '@src/context/GlobalContext';
import './index.scss';

const SiderMenu = () => {
  const { state: { theme } } = useContext(GlobalContext);
  const [selectKey, setSelectKey] = useState('');
  const localtion = useLocation();
  const navigate = useNavigate();
  const list = useMemo(() => {
    const _list: any = [];
    for(let i = 0; i < routeList.length; i++) {
      if (routeList[i].meta?.menu) {
        routeList[i].children?.forEach(route => {
          if (route.meta.menu) {
            _list.push({
              key: route.path,
              icon: route.meta.icon,
              label: route.meta.label
            })
          }
        })
      }
    }
    return _list;
  }, [])
  const selectHandle = ({ key }: any) => {
    setSelectKey(key);
    navigate(key);
  }

  useEffect(() => {
    setSelectKey(localtion.pathname)
  },[])
  return (
    <Menu
      theme={theme}
      mode="inline"
      selectedKeys={[selectKey]}
      items={list}
      onSelect={selectHandle}
    />
  )
}

export default SiderMenu;