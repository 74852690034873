import axios from 'axios';
import { message } from 'antd';
const instance = axios.create({
  baseURL: '/',
  timeout: 1800000,
  headers: {
    "Content-Type": "application/json"
  }
})

instance.interceptors.request.use((config) => {
  const access_token = localStorage.getItem("access_token");
  if (access_token) {
    config.headers.Authorization = `bearer ${access_token}`;
  }
  // config.headers["ngrok-skip-browser-warning"] = true;
  return config;
}, (error) => {
  return Promise.reject(error.message);
})

instance.interceptors.response.use((response) => {
  return response.data;
},(error) => {
  if (error.response.data && error.response.data.detail == "Not authenticated") {
    window.location.href="/login";
    return {}
  }
  const error_obj = {code: 1, msg: error.message};
  if (error?.response?.status >= 400 && error?.response?.status < 500 ) {
    if (error?.response?.data.detail && typeof error?.response?.data.detail === "string") {
      error_obj.msg = error?.response?.data.detail
    }
  }
  message.error(error_obj.msg)
  return error_obj;
  // return Promise.reject(error.message);
})

export default instance;
